import { yousignKey, yousignUrl, yousignAppUrl } from "config";

var myHeaders = new Headers();
myHeaders.append("Authorization", `Bearer ${yousignKey}`);
myHeaders.append("Content-Type", "application/json");

var requestOptions = {
  method: "GET",
  headers: myHeaders,
  redirect: "follow",
};
export const yousignRequest = ({ path }) =>
  fetch(`${yousignUrl}${path}`, requestOptions);
export const yousignGetMemberSignatureUrl = (yousign_client_id) => {
  if (yousign_client_id.startsWith('http')) {
    return yousign_client_id;
  }

  return `${yousignAppUrl}/procedure/sign?members=${yousign_client_id}`;
};
